
$(document).on('turbolinks:load', function(){	

	$('.nav-subscribe-button button').click(function(){
		$(this).hide().parent().next('.hidden-form').addClass('active');
	});

   $('#new-subscriber-button').click(function() {
    $(this).val('One moment...');
  });

  $('.story-signup-bar-wrapper .close-button').click(function(){
    $('.story-signup-bar-wrapper').hide();
    $(".story-blocker").hide();
    Cookies.set('signup-bar', 'hide', { expires: 30 });
  });

   $('.story-signup-bar-wrapper .close-text').click(function(){
    $('.story-signup-bar-wrapper').hide();
    $(".story-blocker").hide();
    Cookies.set('signup-bar', 'hide', { expires: 30 });
  });

  var signupbar = $(".story-signup-bar-wrapper");
  var blocker = $(".story-blocker");
  var singupbarStatus = Cookies.get('signup-bar');

  $(window).scroll(function() {
      var scroll = $(window).scrollTop();
      if(singupbarStatus != 'hide') {
        if (scroll >= 300) {
            signupbar.addClass("visible");
            blocker.addClass("visible");
        } else {
            signupbar.removeClass("visible");
            blocker.removeClass("visible");
        }
      }
  });


  $("#edit-story-list").sortable({
    update: function(e, ui) {
      console.log($(this).sortable('serialize'));
      Rails.ajax({
        url: $(this).data("update-url"),
        type: "PATCH",
        data: $(this).sortable('serialize'),
      });
    }
  });

  $("#edit-leader-list").sortable({
    update: function(e, ui) {
      console.log($(this).sortable('serialize'));
      Rails.ajax({
        dataType: 'script',
        url: $(this).data("update-url"),
        type: "PATCH",
        beforeSend: function() {
          return true
        },
        data: $(this).sortable('serialize'),
      });
    }
  });

  $("#edit-chair-list").sortable({
    update: function(e, ui) {
      console.log($(this).sortable('serialize'));
      Rails.ajax({
        dataType: 'script',
        url: $(this).data("update-url"),
        type: "PATCH",
        beforeSend: function() {
          return true
        },
        data: $(this).sortable('serialize'),
      });
    }
  });

  $("#edit-sponsor-list").sortable({
    update: function(e, ui) {
      console.log($(this).sortable('serialize'));
      Rails.ajax({
        dataType: 'script',
        url: $(this).data("update-url"),
        type: "PATCH",
        beforeSend: function() {
          return true
        },
        data: $(this).sortable('serialize'),
      });
    }
  });

  $("#edit-speakers-list").sortable({
    update: function(e, ui) {
      console.log($(this).sortable('serialize'));
      Rails.ajax({
        dataType: 'script',
        url: $(this).data("update-url"),
        type: "PATCH",
        beforeSend: function() {
          return true
        },
        data: $(this).sortable('serialize'),
      });
    }
  });

  $("#edit-resource-items-list").sortable({
    update: function(e, ui) {
      console.log($(this).sortable('serialize'));
      Rails.ajax({
        url: $(this).data("update-url"),
        type: "PATCH",
        data: $(this).sortable('serialize'),
      });
    }
  });


  $(".load-more button").click(function() {
    var page = $(this).data("page") || 2; // Start with page 2
    var show = $(this).data("show");
    var url = "/shows/" + show + "?page=" + page;

    $.ajax({
      url: url,
      method: "GET",
      dataType: "script",
      success: function(data) {
       
        $(".load-more button").data("page", page + 1);
      }
    });
  });

  $('#show-signup-form').click(function(){
    $('.new-account-form').show();
  });


});

