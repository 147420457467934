$(document).on('turbolinks:load', function(){

  let cb = new ClipboardJS('.copy-link');
  $('.copy-link').on('click', function() {
    let theButton = $(this);
    var copy_id = $(this).attr('id');
    var clipboard = new ClipboardJS( '#' + copy_id );

    clipboard.on('success', function(e) {
      theButton.text('Copied');; 
    });

  });

});
