// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import $ from 'jquery';
global.$ = jQuery;
window.$ = window.jQuery = jQuery;

import 'bootstrap'
//import 'controllers'

import Rails from "@rails/ujs";
window.Rails = Rails;
if(Rails.fire(document, "rails:attachBindings")) {
  Rails.start();
}
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

window.Cookies = require("js-cookie")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("jquery-ui")

require("trix")
require("@rails/actiontext")

require("../administrate/index")
require('./nested-forms/addFields')
require('./nested-forms/removeFields')
require('./clipboard/clipboardButtons');
require('./slick/slick');
require('./best_in_place');
require('./custom');
require("@nathanvda/cocoon")



